










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Endpoint extends Vue {
	@Prop({ required: true, type: String }) readonly method!: string;
	@Prop({ required: true, type: String }) readonly route!: string;
	@Prop({ required: true, type: String }) readonly version!: string;

	private baseRoute = "https://api.parklio.com/";

	get fullRoute(): string {
		return this.baseRoute + this.version + "/" + this.route;
	}
}
