














































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Endpoint extends Vue {
	@Prop({ required: false, type: Object }) readonly responses!: Record<
		string,
		string
	>;
	@Prop({ required: true, type: Array }) readonly statuses!: Array<string>;

	private types = ["schema", "example"];
	private selectedType = "schema";
	private selectedStatus: string | null = null;

	mounted() {
		this.selectedStatus = this.statuses[0];
	}

	upperCase(type: string) {
		return type.charAt(0).toUpperCase() + type.slice(1);
	}
}
