









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Authorization extends Vue {}
