
















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Request extends Vue {
	@Prop({ required: true, type: Array }) readonly parameters!: Array<string>;
	private types = ["schema", "example"];
	private selectedType = "schema";
	private selectedParameter: string | null  =	null;
	private selectedStatus: string | null = null;

  mounted(){
    this.selectedParameter = this.parameters.length > 0 ? this.parameters[0] : "";
  }

	upperCase(value: string) {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}
}
